const flatten = require("flat");
const messages = {
  GENERAL: {
    SAVE: "SAVE",
    EDIT: "EDIT",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    RESET: "RESET",
    ACTIONS: "Actions",
    TOTAL: "TOTAL",
    ID: "ID",
    PLACEHOLDER_SEARCH: "Search...",
    REQUIRED_FIELD: "Required field",
    REQUIRED_DIFFERENT_ANY: "Field cannot have only Any object",
    CANCEL: "Cancel",
    OK: "Ok",
    WARNING: "Warning",
    INFO: "Information",
    CONFIRM_DELETE: "Are you sure do you want to delete this?",
    EDIT_SMALL: "Edit",
    DELETE_SMALL: "Delete",
    DELETE_COMPLETELY: "Delete Completely",
    BROWSE: "Browse",
    EDIT_OBJECT: "Edit Object",
    WHERE_USED: "Where Used",
    NOT_IN_USE: "Not in use",
    SAVE_SMALL: "Save",
    NO_DATA: "No data to display",
    SWW: "Something went wrong",
    ERROR: "Error",
    SUCCESS: "Success",
    SAVE_SUCCESS: "Saved Successfully",
    UPDATE_SUCCESS: "Updated Successfully",
    DELETE_SUCCESS: "Deleted Successfully",
    MIN_LENGTH: "Minimum length should be {length}",
    CURRENTLY_NOT_AVAILABLE: "Currently not available",
    PUBLISHING_CHANGES: "Publishing the changes, please wait...",
    VERIFYING_CHANGES: "Verifying the changes, please wait...",
    INSTALLING_CHANGES: "Installing the changes, please wait...",
    DISCARDING_CHANGES: "Discarding the changes, please wait...",
    BATCH_PROCESSING: "Processing batch changes...",
    FAILED_TO_PUBLISH: "Failed to publish policy.",
    FAILED_TO_INSTALL: "Failed to install policy.",
    LOCKED: "Locked",
    LOADING: "Loading",
    INVALID_OBJECT_NAME: "Should always start with characters",
    INVALID_IP_ADDRESS: "Invalid IP Address",
    INVALID_EMAIL: "Invalid email",
    REFRESH: "Refresh",
    DISCARD_SESSION: "Are you sure, this will discard changes of other users!!",
    FUTURE_RELEASE: "Will be available in a future release",
    AVAILABLE: "Available",
    UNAVAILABLE: "Unavailable",
    ACTIVE: "Active",
    ABOUT_TO_EXPIRE: "About to expire",
    EXPIRED: "Expired",
    ALL: "All",
    ADD: "Add",
    SYNC: "Sync",
    SELECT: "Select option",
    SORT: "Sort",
    UPLOAD: "Upload file",
    IP_ADDRESS: "IP address",
    IP_ADDRESS_V4_V6: "IP address (IPv4/IPv6)",
    RECEIVE_MAILS: "Receive mails",
    REVERT_CHANGES: "Reverting changes",
    REVERT_SUCCESS: "Reverted Successfully",
    HAS_SPECIAL_CHARACTERS: "Should not contain any special character",
    CONFIRM_LOGIN: "There is another user connected with these credentials. ¿Do you wish to take over the session or log in as a read only user?",
    TAKE_OVER: "Take over",
    READ_ONLY_MODE: "Read only mode",
    DEFAULT: "Default",
    ANY: "Any"
  },
  SIDEBAR: {
    DASHBOARD: "Dashboard",
    TUNNELS: "Tunnels",
    ACCESS_CONTROL: "Access Control",
    THREAT_PEVENTION: "Threat Prevention",
    SSL_INSPECTION: "SSL Inspection",
    IDENTITY_AWARENESS: "Identity Awareness",
    ACCOUNTS: "Accounts",
    LOGS: "Logs and Reports",
    TENANTS: "Tenants",
    ADMINISTRATORS: "Administrators",
    ADMIN: "Administration",
    FIREWALLS_CLUSTERS: "Firewalls & Clusters",
    LICENSES: "Licenses",
    MAINTENANCE: "Maintenance"
  },
  NAVBAR: {
    LOGOUT: "Logout",
    OBJECT_EXPLORER: "Object Explorer",
    CHANGES: "Changes",
    INSTALL_POLICY: "Install Policy",
    DISCARD_CHANGES: "Discard Changes",
    CONFIRM: "Confirm",
    CONFIRM_INSTALL: "Are you sure you want to install policy?",
    CONFIRM_DISCARD: "Are you sure you want to discard the changes?",
    MANAGE_POLICIES: "Manage policies",
    POLICY_EXPLORER: "Policy Explorer"
  },
  OBJECT_EXPLORER: {
    NETWORK_GROUPS: "Network Objects",
    NETWORK_ELEMENTS: "Network Elements",
    NETWORKS: "Networks",
    NETWORK_GROUP: "Network Groups",
    CLIENT_NETWORK_GROUP: "Clients",
    ACCESS_ROLES: "Access Roles",
    SERVICES: "Services",
    APPLICATIONS: "Applications",
    APPLICATIONS_DEFAULT: "Predefined Applications",
    APPLICATIONS_CUSTOM: "Custom Applications / URLs",
    APPLICATION_GROUPS: "Application Groups",
    CATEGORIES: "Categories",
    DOMAIN: "Hostnames/FQDN",
    PATTERNS: "DLP",
    POLICIES: "Policies",
    UPDATABLE_OBJECTS: "Updatable Objects",
    PATTERNS_CONTENT: "Content",
    PATTERNS_FILE: "File",
    ADDRESS_RANGE: "Address range",
  },
  SERVICE_OBJECTS: {
    LOAD_ERROR: "Error while loading Service objects",
    SELECT_ERROR: "Error while selecting Service",
  },
  NETWORK_GROUPS: {
    NAME: "Name",
    IPV4_ADDRESS: "IPv4 Address",
    MASK: "Mask",
    IPV6_ADDRESS: "IPv6 Address",
    MASK6: "Mask6",
    NAT_PROPERTIES: "NAT Properties",
    COMMENTS: "Comments",
    SEARCH: "Search...",
    NETWORK: "Network",
    GROUPS: "Groups",
    ADD_NETWORK: "Add Network",
    NEW_NETWORK: "New Network",
    EDIT_NETWORK: "Edit Network",
    ADD_CLIENT: "Add Client",
    EDIT_CLIENT: "Edit Client",
    ENTER_OBJECT_NAME: "Enter Object Name",
    ENTER_OBJECT_COMMENT: "Enter Object Comment",
    GENERAL: "General",
    NAT: "NAT",
    TYPE: "Type",
    IPV4: "IPv4",
    NETWORK_ADDRESS: "Network Address",
    NET_MASK: "Net Mask",
    BROADCAST_ADDRESS: "Broadcast Address",
    INCLUDED: "Included",
    NOT_INCLUDED: "Not Included",
    IPV6: "IPv6",
    PREFIX: "Prefix",
    ADD_TAG: "Add Tag",
    NETWORK_LIST: "Network List",
    NETWORK_IP_ADDRESS: "Network / IP Address",
    LAN: "LAN",
    NO_LAN: "No LAN",
    ADD_NETWORK_GROUP: "Add Network Group",
    INVALID_IP_MASK: "Invalid Ip address with subnetmask",
    EDIT_NETWORKS: "Edit Networks",
    NETWORK_UPDATE_SUCCESS: "Networks updated successfully",
    GROUP_CREATED_SUCCESS: "Group Created Successfully",
    NETWORK_ERROR: "Error on {network_name}",
    INVALID_NETWORK: "Invalid network IP address mask",
    NETWORK_DELETED_SUCCESS: "Network removed from Group",
    REMOVE_NETWORK_FROM_GROUP: "Remove Network From Group",
    DETACH_GROUP: "Remove Group From List",
    GROUP_DETACHED_SUCCESS: "Group detached successfully",
    DETACH: "Detach",
    GROUP_UPDATE_SUCCESS: "Group name updated successfully",
    GROUP_DELETE_SUCCESS: "Group deleted successfully",
    PRIVATE: "Private",
    PRIVATE_INVALID_NETWORK:
      "Only private addresses are allowed in a private address group.",
    PUBLIC_INVALID_NETWORK:
      "Only public addresses are allowed in a non private group.",
    INVALID_PUBLIC_ADDRESS:
      "For a public IP, subnet length can only be between 24 and 32.",
    NETWORK_IP_ADDRESS_EXISTS: "IP address already exists",
    ADD_NETWORK_OBJECT: "Add Network Object",
    EDIT_NETWORK_OBJECT: "Edit Network Object",
    NETWORK_OBJECT_CREATED_SUCCESS: "Network object created Successfully",
    NETWORK_OBJECT_CREATED_ERROR: "Error while creating Network object",
    NETWORK_OBJECT_UPDATED_SUCCESS: "Network object updated successfully",
    NETWORK_OBJECT_UPDATED_ERROR: "Error while updating Network object",
    NETWORK_OBJECT_DELETED_SUCCESS: "Network object deleted successfully",
    NETWORK_OBJECT_DELETED_ERROR: "Error while deleting Network object",
    LOAD_ERROR: "Error while loading Network objects",
    SELECT_ERROR: "Error while selecting Network object",
    ALREADY_IN_USE:
      "The object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    CLIENT_LOAD_ERROR:"Error while loading Clients",
    CLIENT_SELECT_ERROR:"Error while selecting Clients",
    CLIENT_DELETE_SUCCESS:"Client deleted successfully",
    CLIENT_CREATED_SUCCESS:"Client created successfully",
    CLIENT_UPDATED_SUCCESS:"Client updated successfully",
    FIRST_IP_ADDRESS:"First IP address",
    LAST_IP_ADDRESS:"Last IP address",
    ADD_ADDRESS_RANGE_OBJECT: "Add Address Range Object",
    ADDRESS_RANGE_CREATED_SUCCESS: "Address range created Successfully",
    ADDRESS_RANGE_UPDATED_SUCCESS: "Address range updated successfully",
    ADDRESS_RANGE_DELETED_SUCCESS: "Address range deleted successfully",
    ADDRESS_RANGE_LOAD_ERROR: "Error while loading address range object",
    ADDRESS_RANGE_SELECT_ERROR: "Error while selecting address range object",
    INVALID_IP_ADDRESS: "Invalid IP address",
    NATED_IP:"NATed Network / IP Address",
    NATED_IPV4:"Nated IPv4",
    ORIGINAL_IP: "Original Network / IP Address",
    ORIGINAL_IPV4: "Original IPv4"
  },
  APPLICATIONS_CATEGORIES: {
    NAME: "Name",
    COMMENTS: "Comments",
    TAGS: "Tags",
    MODIFIER: "Modifier",
    LAST_MODIFIED: "Last Modified",
    LOAD_ERROR: "Error while loading Application Categories",
  },
  APPLICATIONS: {
    CUSTOM: "Custom Applications / URLs",
    NAME: "Name",
    EDIT_APPLICATION: "Edit Application",
    CREATE_APPLICATION: "Create Application",
    APPLICATION_CREATE_SUCCESS: "Application created successfully",
    APPLICATION_DELETE_SUCCESS: "Application deleted successfully",
    APPLICATION_UPDATE_SUCCESS: "Application updated successfully",
    URLS_AS: "URLs are defined as:",
    WILDCARD: "Wildcards",
    REGEX: "Regular expressions",
    URL_LIST: "URL List",
    DEFAULT: "Predefined Applications",
    LOAD_ERROR: "Error while loading Applications",
    GROUPS: "Application Groups",
    NEW_GROUP: "Add group",
    EDIT_GROUP: "Edit group",
    APPLICATIONS_LIST: "Applications list",
    ENTER_GROUP_NAME: "Enter Group Name",
    GROUP_CREATED_SUCCESS: "Application group created successfully",
    NO_APPLICATIONS: "No applications",
    APPLICATION_UPDATED_SUCCESS: "Application updated successfully",
    GROUP_DELETED_SUCCESS: "Application group deleted successfully",
  },
  DASHBOARD: {
    TUNNELS: "Tunnels info",
    LOGS: "Latest logs",
    TOPS: "Tops",
    REVISIONS: "Latest changes",
    TOTAL: "Total",
    FULL_UP: "Full up",
    UP: "Up",
    DOWN: "Down",
  },
  DOMAIN: {
    ADD_DOMAIN: "Add Hostname/FQDN",
    EDIT_DOMAIN: "Edit Hostname/FQDN",
    ENTER_OBJECT_NAME: "Enter Object Name",
    ENTER_OBJECT_COMMENT: "Enter Object Comment",
    CONTENT: "The Gateway will enforce the following FQDN :",
    FQDN: "FQDN (Fully qualified domain name)",
    INVALID_OBJECT: "Invalid Object",
    VALIDATION: 'Should always start with a "." character',
    LOAD_ERROR: "Error while loading Hostnames/FQDNs",
    SELECT_ERROR: "Error while selecting Hostname/FQDN",
    ALREADY_IN_USE:
      "The object cannot be deleted as it is used in some other places",
    HELP_TITLE: "Hostname/FQDN Help",
  },
  SERVICES: {
    NAME: "Name",
    PORT: "Port",
    PROGRAM_NUMBER: "Program Number",
    ICMP_TYPE: "ICMP Type",
    ICMP_CODE: "ICMP Code",
    COMMENTS: "Comments",
    ADD_SERVICE: "Add Service",
    NEW_TCP_SERVICE: "New TCP Service",
    NEW_UDP_SERVICE: "New UDP Service",
    NEW_ICMP_SERVICE: "New ICMP Service",
    NEW_OTHER_SERVICE: "New OTHER Service",
    EDIT_TCP_SERVICE: "Edit TCP Service",
    EDIT_UDP_SERVICE: "Edit UDP Service",
    EDIT_ICMP_SERVICE: "Edit ICMP Service",
    EDIT_OTHER_SERVICE: "Edit OTHER Service",
    GENERAL: "GENERAL",
    ADVANCED: "Advanced",
    PROTOCOL: "Protocol",
    MATCH_BY: "MATCH BY",
    ADD_TAG: "Add Tag",
    TCP_SERVICE: "TCP Service",
    UDP_SERVICE: "UDP Service",
    ICMP_SERVICE: "ICMP Service",
    OTHER_SERVICE: "Other Services",
    NO_ITEM_SELECTED: "No item selected",
    IP_PROTOCOL: "IP Protocol",
    CONNECTION_OPEN:
      "Keep Connection open after the policy has been installed.",
    INVALID_PORT: "Invalid port",
    INVALID_PROTOCOL: "Invalid protocol",
    HAS_WHITE_SPACE: "Should not contain any whitespace",
  },
  PATTERNS: {
    PATTERNS: "Regular expressions",
    URL: "DLP",
    FILE: "DLP File",
    NAME: "Name",
    CREATE_PATTERN: "Add pattern",
    EDIT_PATTERN: "Edit pattern",
    DELETE_PATTERN: "Delete pattern",
    REGEX_SYNTAX: "Regular Expression syntax",
    REGEX_SYNTAX_TITLE: "Regular expressions syntax guideline",
    PATTERN_CREATE_SUCCESS: "Signature created successfully",
    PATTERN_CREATE_ERROR: "Error while creating Signature",
    PATTERN_UPDATE_SUCCESS: "Signature updated successfully",
    PATTERN_UPDATE_ERROR: "Error while updating Signature",
    PATTERN_DELETE_SUCCESS: "Signature deleted successfully",
    PATTERN_DELETE_ERROR: "Error while deleting Signature",
    LOAD_ERROR: "Error while loading Signatures",
  },
  TUNNELS: {
    TUNNELS: "Tunnels",
    NAME: "Name",
    LOCATION: "Location",
    IP_HOSTNAME: "Hostname/IP",
    IP: "IP",
    NETWORKS: "Networks",
    HOST_ADDRESS: "Resolved IP address",
    HOSTNAME: "Hostname",
    CHECK_ADDRESS: "CHECK ADDRESS",
    HOSTNAME_NOT_RESOLVED: "Hostname address not resolved",
    STATUS: "Status",
    ADD_NEW_TUNNEL: "Add new tunnel",
    GENERAL: "GENERAL",
    SITE_ADDRESS: "Site Address",
    CONNECTION_DETAILS: "CONNECTION DETAILS",
    EXTERNAL_IP_ADDRESSES: "IP address mode",
    DYNAMIC_IP_ADDRESS: "External Dynamic IP",
    INTERNAL_DYNAMIC_IP_ADDRESS: "Internal Dynamic IP",
    STATIC_IP_ADDRESS: "Static",
    AUTHENTICATION: "AUTHENTICATION",
    SELECT_YOUR_AUTH_OPTION: "Select your auth option",
    SHARED_KEY: "Shared Key",
    UPLOAD_CERTIFICATE: "Upload Certificate",
    SELECT_CERTIFICATE: "Select certificate",
    INTERNAL_SUB_NETWORKS: "INTERNAL SUB-NETWORKS",
    ADD_SUB_NETWORKS: "Add sub-networks",
    INVALID_HOST: "Invalid host",
    UPDATE_TUNNEL: "Update Tunnel",
    CREATE_SUCCESS: "Tunnel created successfully",
    DELETE_SUCCESS: "Tunnel deleted successfully",
    UPDATE_SUCCESS: "Tunnel updated successfully",
    CONFIRM_RESET: "Are you sure do you want to reset the shared-key?",
    VPN_LOCKED: "VPN is locked by other user",
    SHARED_KEY_LETTER_COUNT: "Must contain atleast 4 different characters",
    PUBLISH_CREATE: "Publish and Create",
    PENDING_VALIDATION:
      "Before creating a tunnel, please Install or Discard your pending changes.",
    LOAD_ERROR: "Error while loading Tunnels",
    UPLOAD_TUNNELS: "Batch Tunnel Upload",
    WORK_IN_PROGRESS: "Work in progress",
    FILE: "File",
    SELECT_FILE: "Select file",
    UPLOAD: "Upload",
    BATCH_SUCCESS: "Tunnels created successfully",
    BATCH_ERROR: "Error while uploading file",
    BATCH_WARNING: "Process finished with warnings:",
    FILE_HELP_TITTLE: "Batch tunnels file help",
    HELP_TITTLE: "Tunnels Help",
    FULL_UP: "Up (P1 & P2)",
    UP: "Up (P1)",
    DOWN: "Down",
    HOST_UNAVAILABLE: "The hostname is not available",
    HOST_LIST_LOADING: "Awaiting the available hostnames list",
    NO_VPN: "Selected policy have no VPN associated",
    CONFIRM_DELETE_BATCH: "Are you sure you want to delete the selected tunnels?",
    DELETE_BATCH_SUCCESS: "Selected tunnels deleted successfully",
    LOCAL_ID: "Local ID",
    INTERNAL_DYNAMIC_IP_TOOLTIP: "IP must be different from any other IP tunnel or object configured",
  },
  POLICIES: {
    ACCESS_CONTROL: "Access Control",
    ACCESS_RULE: "Access Rule",
    ACTION: "Action",
    NAME: "Name",
    SOURCE: "Source",
    DESTINATION: "Destination",
    ADD_NEW_POLICY: "Add new rule",
    UPDATE_POLICY: "Update rule",
    ACCEPT: "Accept",
    DROP: "Drop",
    ALLOW: "Allow",
    BLOCK: "Block",
    ENABLE: "Enable",
    DISABLE: "Disable",
    ORDER: "Order",
    ORDER_ERROR: "Order must between 1 to {orderLimit}",
    PUBLISH_SUCCESS: "Successfully published the changes",
    DISCARD_SUCCESS: "Successfully discarded the changes",
    CONFIRM_DISABLE: "Are you sure you want to disable this rule?",
    CONFIRM_ENABLE: "Are you sure you want to enable this rule?",
    CREATE_SUCCESS: "Policy created successfully",
    DELETE_SUCCESS: "Policy deleted successfully",
    UPDATE_SUCCESS: "Policy updated successfully",
    AUTHENTICATION_RULE: "Authentication Rule",
    ADD_RULE_ABOVE: "Add rule above",
    ADD_RULE_BELOW: "Add rule below",
    ADD_RULE_TOP: "Add rule to top",
    ADD_RULE_BOTTOM: "Add rule to bottom",
    MOVE_RULE_ABOVE: "Move rule above",
    MOVE_RULE_BELOW: "Move rule below",
    SELECT_HTTP_ERROR: "Please include HTTP or HTTPS from TCP services",
    RULE_LOCKED: "The policy is locked by another user",
    LOAD_ERROR: "Error while loading Policy",
    DEST_LAN_ERROR:
      "Network objects (Networks and Network Groups) should be all LAN type or no LAN type, both types are not allowed at the same time.",
    SELECTED_POLICY: "Selected Policy",
    TARGETS: "Targets",
    SOURCE_NUMBER_ERROR: "Only one source object is allowed.",
    AUTHENTICATION_RULE_HELP_TITLE: "Authentication Rule Help",
    HITS: "Hits",
    ADD_SECTION: "Add section",
    ADD_SUCCESS: "Section added successfully",
    DELETE_SECTION_SUCCESS: "Sección deleted successfully"
  },
  THREAT_PREVENTION: {
    THREAT_PREVENTION: "Threat Prevention",
    THREAT_RULE: "Threat Prevention Rule",
    PROTECTION_PROFILE: "PROTECTION PROFILE",
    PROFILE_TECHNOLOGIES: "PROFILE TECHNOLOGIES",
    SANDBOX: "Sandbox",
    THREAT_CLOUD: "Threat Cloud",
    C_C_PROTECTION: "C&C Protection",
    IPS_PROTECTION: "IPS Protection",
    IDS_PROTECTION: "IDS Protection",
    FILE_URL_PROTECTION: "File & URL Protection",
    EXPLOIT_PROTECTION: "Exploit Protection",
    EXCEPTIONS: "Exceptions",
    NAME: "Name",
    SOURCE: "Source",
    DESTINATION: "Destination",
    PROTECTION: "Protection",
    THREAT_PROTECTIONS: "Threat Protections",
    SERVICE: "Service",
    ACTION: "Action",
    STATUS: "Status",
    ORDER: "Order",
    ENABLE: "Enable",
    DISABLE: "Disable",
    CONFIRM_DISABLE: "Are you sure you want to disable this exception?",
    CONFIRM_ENABLE: "Are you sure you want to enable this exception?",
    ADD_RULE_ABOVE: "Add rule above",
    ADD_RULE_BELOW: "Add rule below",
    ADD_RULE_TOP: "Add rule to top",
    ADD_RULE_BOTTOM: "Add rule to bottom",
    MOVE_RULE_ABOVE: "Move rule above",
    MOVE_RULE_BELOW: "Move rule below",
    IPS_DISABLED:
      "IPS/IDS protection is not enabled, therefore threat exception will not work. You may have pending changes to publish.",
    CONTENT:
      "Optimized security for users. Prevents cyberattacks. Includes protection for users browsing the web, working   with desktop applications, accessing corporate applications, sharing files over FTP or using other network protocols. This is the recommended profile for multiple protections on the traffic between users and applications",
    ADD_EXCEPTION: "Add exception",
    UPDATE_EXCEPTION: "Update exception",
    PROFILE_UPDATE_SUCCESS: "Threat profiles saved",
    EXCEPTION_CREATE_SUCCESS: "Threat exception created successfully",
    EXCEPTION_UPDATE_SUCCESS: "Threat exception updated successfully",
    EXCEPTION_DELETE_SUCCESS: "Threat exception deleted successfully",
    EXCEPTION_ORDER_ERROR: "Order must between 1 to {orderLimit}",
    GLOBAL_EXCEPTIONS_LOCKED: "Exceptions are locked by other user",
    THREAT_TASK_FINISHED: "Threat profile update finished",
    THREAT_TASK_ERROR:
      "There was an error while applying Threat Profile changes",
    PROFILES_UPDATING: "Threat profiles are updating, please wait.",
    LOAD_ERROR_EXCEPTIONS: "Error while loading Threat Exceptions",
    LOAD_ERROR_PROFILES: "Error while loading Threat Profiles",
    PROFILE_LOCKED: "Threat profile is locked by another user",
    DEFAULT_PROFILE: "Default",
  },
  SSL_INSPECTION: {
    SSL_INSPECTION: "SSL Inspection",
    SSL_EXCEPTION: "SSL Exceptions",
    ACTION: "Action",
    NAME: "Name",
    SOURCE: "Source",
    DESTINATION: "Destination",
    SITE_CATEGORY: "Site Category",
    POSITION: "Position",
    SELECT_YOUR_SSL: "SELECT YOUR SSL INSPECTION MODE",
    SSL_INSPECTION_MODE: "SSL Inspection Mode",
    BASIC: "Basic",
    FULL: "Full",
    DOWNLOAD_FULL: "Download full inspection certificate",
    DOWNLOAD_CERTIFICATE: "DOWNLOAD CERTIFICATE",
    DOWNLOADED_CERTIFICATE: "Downloaded certificate",
    DOWNLOAD_FAILED:
      "Failed to download, please refresh the page and try again.",
    DOWNLOADING: "Downloading",
    UPLOAD_CERTIFICATE: "Upload Certificate",
    UPLOAD_YOUR_OWN_CERTIFICATE: "Upload your own certificate",
    EXCEPTIONS: "EXCEPTIONS",
    DO_NOT_INSPECT: "Do not inspect the following sources",
    ADD_SSLINSPECTION: "Add Exception",
    AUTHENTICATION_RULE: "Authentication Rule",
    UPDATE_SSLINSPECTION: "Update Exception",
    ACTIVATE_FULL: "Do not inspect the following destinations",
    CONFIRM_DISABLE: "Are you sure you want to disable this SSL Inspection?",
    CONFIRM_ENABLE: "Are you sure you want to enable this SSL Inspection?",
    UPDATE_SUCCESS: "Exception updated successfully",
    CREATE_SUCCESS: "Exception created successfully",
    RULE_LOCKED: "The exception is locked by another user",
    ADD_RULE_ABOVE: "Add rule above",
    ADD_RULE_BELOW: "Add rule below",
    ADD_RULE_TOP: "Add rule to top",
    ADD_RULE_BOTTOM: "Add rule to bottom",
    ENABLE: "Enable",
    DISABLE: "Disable",
    LOAD_ERROR: "Error while loading SSL Inspections",
    SSL_MODE_CREATE_SUCCESS: "SSL Inspection Mode saved successfully",
  },
  IDENTITY_AWARENESS: {
    IDENTITY_AWARENESS: "Identity Awareness",
    SELECT_YOUR_IDENTITY_PROVIDER: "SELECT YOUR IDENTITY PROVIDER",
    PROVIDER: "Provider",
    VERIFY_DOMAIN: "VERIFY DOMAIN",
    DOMAIN: "Domain",
    IDENTITY_AWARENESS_SECTION_HEADING: "IDENTITY AWARENESS",
    ENABLE_IDENTITY_AWARENESS_FOR_USERS: "Enable Identity Awareness for users",
    ENABLE_IDENTITY_AWARENESS_FOR_SITES: "Enable Identity Awareness for sites",
    BYPASS_AUTHENTICATION_FOR_THESE_SOURCES:
      "Bypass authentication for these sources",
    PLACEHOLDER_DOMAIN: "e.g: mycompany.com",
  },
  ACCOUNTS: {
    ACCOUNTS: "Accounts",
    TENANTS: "Tenants",
    NAME: "Customer",
    USER_NAME: "User Name",
    SERVER_HOST: "Server Host",
    PRIVATE_SERVER_IP: "Private server IP",
    ADD_ACCOUNT: "Add Account",
    EDIT_ACCOUNT: "Edit Account",
    PASSWORD_RESET: "Reset Password",
    NEW_PASSWORD: "New Password",
    PASSWORD: "Password",
    REQUIRED_SERVER_HOST: "Invalid server host",
    REQUIRED_PRIVATE_SERVER_IP: "Invalid private server ip",
    CONFIRM_RESET: "Are you sure you want to reset the password?",
    CLEANUP: "Cleanup the Sessions",
    SESSIONS_CLEANED: "Sessions Cleaned",
    CLEAN_ERROR: "Error while cleaning sessions",
    MULTIPLE_FIREWALL: "Multiple Firewall",
    ADD_ACCOUNT_SUCCESS: "Customer added sucessfully",
    ADD_ACCOUNT_ERROR: "Error while creating customer",
    EDIT_ACCOUNT_SUCCESS: "Customer updated sucessfully",
    EDIT_ACCOUNT_ERROR: "Error while updating customer",
    LOAD_ERROR: "Error while loading accounts",
    CONFIRM_DELETE: "Are you sure you want to delete the account? Introduce the name of the account to confirm: ",
    DELETE_SUCCESS: "Account deleted successfully",
    CONFIRM_ERROR: "The confirmation is incorrect",
    DATACENTER1: "Tunnel 1 IP",
    DATACENTER2: "Tunnel 2 IP",
    REQUIRED_DATACENTER: "At least one Datacenter IP is required",
    DATACENTER_IP_INVALID: "Some Datacenter IP is not valid",
    ACCOUNT_TYPE: "Account type",
    ACCOUNT_TYPE_REQUIRED: "The account type is required",
    STANDARD: "Standard",
    CARRIER: "Carrier",
    CARRIER_CGNAT: "CGNAT - Carrier",
    STANDARD_CGNAT: "CGNAT - Standard",
    CONTRACT_TB: "Contract TB",
    CONTRACT_TB_INVALID: "Contract TB are not valid",
    ADMINISTRATORS: "Administrators",
    OSDX_HOST: "Osdx host",
    OSDX_PUBLIC: "Public IP",
    OSDX_PRIVATE: "Private IP",
    OSDX_GATEWAY: "Gateway IP",
    OSDX_TUNNEL: "Tunnels IP",
    REQUIRED_OSDX_HOST: "Required OSDx Host",
    OSDX_HOST_INVALID: "OSDx Host invalid",
    REQUIRED_OSDX_PRIVATE_IP: "Required OSDx Private IP",
    OSDX_PRIVATE_IP_INVALID: "OSDx Private IP invalid",
    REQUIRED_OSDX_PRIVATE_IP_GATEWAY: "Required OSDx Private IP Gateway",
    OSDX_PRIVATE_IP_GATEWAY_INVALID: "OSDx Private IP Gateway invalid",
    REQUIRED_OSDX_PRIVATE_TUNNEL_IP: "Required OSDx Tunnels IP",
    OSDX_PRIVATE_TUNNEL_IP_INVALID: "OSDx Tunnels IP invalid",
    LOG_LEVEL: "Log level",
    STANDARD_LOG: "Standard log",
    DETAILED_LOG: "Detailed log",
  },
  USERS: {
    USERS: "Users",
    EMAIL: "Email",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    READ_ONLY: "Read Only",
    PRIVILEGES: "Privileges",
    YES: "Yes",
    NO: "No",
    RO: "RO",
    RW: "RW",
    NEW_USER: "Add New User",
    UPDATE_USER: "Update User",
    PASSWORD: "Password",
    CREATE_SUCCESS: "User created successfully",
    UPDATE_SUCCESS: "User updated successfully",
    RESET_PASSWORD_MAIL_SUCCESS: "Reset password mail successfully sent",
    RESET_PASSWORD_SUCCESS: "Password successfully reset",
    REPEAT_PASSWORD: "Repeat password",
    RESET_PASSWORD: "Reset Your Password Account",
    IS_MASTER: "Master user",
    CONFIRM_MASTER_CHANGE: "Are you sure you want to change the master user?",
    TENANT: "Tenant",
    SUPERADMIN: "Superadmin",
    IMPERSONATE: "Impersonate",
    CONFIRM_IMPERSONATE: "Are you sure you want to impersonate another user?",
    API_KEY: "Api key",
    CONFIRM_REFRESH_API_KEY: "Are you sure you want to refresh the api key",
    REFRESH_API_KEY_SUCCESS: "Api key was refreshed successfully",
    REFRESH_API_KEY: "Refresh the api key",
    ADMIN: "Admin",
    ADMIN_RO: "Admin Read only",
    END_USER: "End user",
    END_USER_RO: "Read only",
    CLIENT: "Client",
    ROLE: "Role"
  },
  NETWORK_USERS: {
    NAME: "Name",
    USERS: "Users",
    USERNAME: "Username",
    USERNAME_EXAMPLE: "e.g. username@domain.com",
    EMAIL: "Email",
    COMMENTS: "Comments",
    NEW_USER: "Add User",
    EDIT_USER: "Edit User",
    GROUPS: "Groups",
    PASSWORD: "Password",
    AUTH_METHOD: "Authentication method",
    DEFAULT_AUTH_METHOD: "check point password",
    USER_CREATED_SUCCESS: "User created successfully",
    USER_CREATED_ERROR: "Error while creating user",
    USER_UPDATED_SUCCESS: "User updated successfully",
    USER_UPDATED_ERROR: "Error while updating user",
    USER_DELETED_SUCCESS: "User deleted successfully",
    USER_DELETED_ERROR: "Error while deleting user",
    PASSWORD_LENGTH: "Password must be between 4 and 8 characters",
    PASSWORD_LENGTH_EXT: "Password must be between 8 and 32 characters",
    CONFIRM_RESET: "Are you sure do you want to reset the password?",
    LOAD_ERROR: "Error while loading Network Users",
    SELECT_ERROR: "Error while selecting Network User",
    UPLOAD_USERS: "Upload Users",
    FILE: "File",
    SELECT_FILE: "Select File",
    UPLOAD: "Upload",
    USERS_CREATED_SUCCESS: "CSV Users created successfully",
    USER_TASK_ERROR: "CSV user creation failed. Please try again",
    CSV_PROCESSING:
      "CSV uploaded successfully. Please wait we will notify when users creation is done",
    ERROR_SOME_USER: "Error in creating following users",
    WORK_IN_PROGRESS: "Work in progress",
    CONFIRM_DELETE: "Are you sure you want to delete this user?",
    DELETE_SUCCESS: "User deleted successfully",
    USERS_BATCH_SUCCESS: "Users created successfully",
    FILE_HELP_TITTLE: "Active Directory users file help",
    REPEAT_PASSWORD_NOT_EQUAL: "Passwords doesn't match",
    RESET_PASSWORD_MAIL_SUCCESS: "Password will be reset when installing changes",
    RESET_PASSWORD: "Reset Your Network User Password",
  },
  USER_GROUPS: {
    GROUP: "Group",
    NAME: "Name",
    ENTER_GROUP_NAME: "Enter Group Name",
    IDENTIFIER: "Group identifier",
    IDENTIFIER_EXAMPLE: "e.g. Finance",
    NEW_GROUP: "Add group",
    EDIT_GROUP: "Edit group",
    COMMENTS: "Comments",
    USERS_LIST: "Users list",
    GROUP_CREATED_SUCCESS: "User group created successfully",
    GROUP_CREATED_ERROR: "Error while creating user group",
    GROUP_UPDATED_SUCCESS: "User group updated successfully",
    GROUP_UPDATED_ERROR: "Error while updating user group",
    GROUP_DELETED_SUCCESS: "User group deleted successfully",
    GROUP_DELETED_ERROR: "Errro while deleting user group",
    NO_USERS: "No users",
    LOAD_ERROR: "Error while loading User Groups",
  },
  OBJECT_MODAL: {
    ADD: "Add",
    NETWORK: "Network",
    SERVICE: "Service",
    CANCEL: "Cancel",
  },
  LOGS: {
    LOGS: "Logs",
    TIME: "Time",
    BLADE: "Feature",
    ACTION: "Action",
    ORIGIN: "Origin",
    SOURCE: "Source",
    SOURCE_NAME: "Source Name",
    SOURCE_PORT: "Source Port",
    DESTINATION: "Destination",
    DESTINATION_NAME: "Destination Name",
    SERVICE: "Service",
    SERVICE_DESCRIPTION: "Service Description",
    ACCESS_RULE: "Access Rule",
    ACCESS_RULE_NAME: "Access Rule Name",
    POLICY_NAME: "Policy Name",
    DESCRIPTION: "Description",
    LAST_HOUR_FILTER: "Last hour",
    TODAY_FILTER: "Today",
    LAST_24_HOURS_FILTER: "Last 24 Hours",
    YESTERDAY_FILTER: "Yesterday",
    THIS_WEEK_FILTER: "This Week",
    LAST_7_DAYS_FILTER: "Last 7 Days",
    THIS_MONTH_FILTER: "This Month",
    LAST_14_DAYS_FILTER: "Last 14 Days",
    LAST_30_DAYS_FILTER: "Last 30 Days",
    ALL_TIME_FILTER: "All Time",
    CUSTOM_FILTER: "Custom",
    TOP: "Tops",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    START_DATE_REQUIRED: "Start Date Required",
    SET: "Set",
    TOP_SOURCES: "Sources",
    TOP_DESTINATIONS: "Destinations",
    TOP_SERVICES: "Services",
    TOP_ACTIONS: "Actions",
    TOP_BLADES: "Features",
    TOP_ORIGINS: "Origins",
    TOP_USERS: "Users",
    TOP_APPLICATIONS: "Applications",
    START_DATE_RESTRICTION: "Please pick a valid start date",
    END_DATE_RESTRICTION: "Please pick a valid end date",
    TOPS_ERROR: "Tops Error",
    LOGS_ERROR: "Logs Error",
    LOG_DETAILS: "Log Details",
    LOG_INFO: "Log Info",
    TRAFFIC: "Traffic",
    POLICY: "Policy",
    THREAT: "Threat",
    PROTOCOL: "Protocol",
    PROTOCOL_NAME: "Protocol Name",
    PROTECTION: "Protection",
    SOURCE_OS: "Source OS",
    DETECTED_ON_OS: "Detected on OS",
    FILE_NAME: "File Name",
    MALWARE_ACTION: "Malware Action",
    DOWNLOAD_FILE: "Download File",
    QUERY_SYNTAX: "Query Syntax",
    QUERY_TITLE: "beSafe Premium Query Language",
    SET_CUSTOM_TIME: "Set Custom Time",
    TIME_PERIOD: "Time Period",
    CLEAR_SEARCH: "Clear Search",
    APPLICATION_SITE: "Application / Site",
    APPLICATION_RISK: "Application Risk",
    APPLICATION_NAME: "Application Name",
    APPLICATION_CATEGORY: "Application Category",
    PRIMARY_CATEGORY: "Primary Category",
    APPLICATION_DESCRIPTION: "Application Description",
    CLIENT_TYPE: "Client Type",
    ADDITIONAL_CATEGORIES: "Additional Categories",
    ATTACK_NAME: "Attack Name",
    RESOURCE: "Resource",
    DATA_TYPE: "Data Type",
  },
  ADMIN: {
    ADMIN: "Administration",
    USERS: "Administrators",
    SESSIONS: "Sessions",
    CONFIG: "General configuration",
    SYSLOG: "System logs",
    REVISIONS: "Revisions",
    WEBHOOKS: "Webhooks"
  },
  SESSIONS: {
    NAME: "Name",
    PENDING_CHANGES: "Pending Changes",
    IN_WORK: "Active",
    ORIGIN: "Origin",
    YES: "Yes",
    NO: "No",
    DELETE_ALL: "Discard changes and Disconnect all sessions",
    DELETE: "Discard changes and Disconnect",
    CONFIRM_DELETE: "Are you sure you want to discard the changes of this session and disconnect it?",
    CONFIRM_DELETE_ALL: "Are you sure you want to discard the changes of all sessions and disconnect them?",
    DELETE_SUCCESS: "Session deleted successfully",
    DELETE_ALL_SUCCESS: "All Sessions deleted successfully",
    REFRESH: "Refresh table",
    LOCKED_OBJECTS: "Locked objects",
    PUBLISH_TIME: "Publish time",
    REVERT: "Revert",
    CHANGES: "Changes",
    CONFIRM_REVERT: "Are you sure you want to revert the changes to the selected session?"
  },
  FIREWALLS: {
    FIREWALLS: "Firewalls",
    CLUSTERS: "Clusters",
    FIREWALLS_CLUSTERS: "Firewalls and Clusters",
    ADD_FIREWALL: "Add Firewall",
    EDIT_FIREWALL: "Edit Firewall",
    ADD_CLUSTER: "Add Cluster",
    EDIT_CLUSTER: "Edit Cluster",
    TYPE: "Type",
    STATUS: "Status",
    NAME: "Name",
    IP: "IP",
    VERSION: "Version",
    PASSWORD: "One-time password",
    HARDWARE: "Hardware",
    CPU: "CPU",
    MEMORY: "Memory",
    ACTIVE_BLADES: "Active Features",
    LOAD_ERROR: "Error while loading Firewalls",
    INTERFACES: "Interfaces",
    VPN: "VPN",
    VPN_SETTINGS: "VPN settings",
    AUTOMATIC: "Automatic",
    MANUAL: "Manual",
    VPN_DOMAIN_ADDRESSES: "VPN domain addresses",
    FIREWALL_CREATE_SUCCESS: "Firewall created successfully",
    FIREWALL_CREATE_ERROR: "Error while creating Firewall",
    FIREWALL_UPDATE_SUCCESS: "Firewall updated successfully",
    FIREWALL_UPDATE_ERROR: "Error while updating Firewall",
    FIREWALL_DELETE_SUCCESS: "Firewall deleted successfully",
    FIREWALL_DELETE_ERROR: "Error while deleting Firewall",
    LICENSE_BLADENAME: "Name",
    LICENSE_STATUS: "Status",
    EXPIRATION_DATE: "Expiration",
    LICENSE_ADDITIONAL_INFO: "Additional Information",
    BLADES: "Features",
    RESET_SIC: "Reset SCL",
    CONFIRM_RESET_SIC: "Do you wish to reset the Firewall SCL?",
    ADD_INTERFACE: "Add interface",
    EDIT_INTERFACE: "Edit interface",
    STATE: "State",
    LINK_STATE: "Link State",
    INTERFACES_LOAD_ERROR: "Error while loading interfaces",
    POLICY_INSTALLED: "Policy installed",
    HELP_TITLE: "Create Firewall Help",
    ROUTES: "Routes",
    ROUTE_DESTINATION: "Destination IP",
    ROUTE_MASK: "Mask",
    ROUTE_GATEWAY: "Gateway IP",
    ROUTE_PRIORITY: "Priority",
    ROUTES_LOAD_ERROR: "Error while loading routes",
    ROUTE_DELETE_SUCCESS: "Route deleted successfully",
    IP_INVALID: "The IP is invalid",
    MASK_INVALID: "The mask is invalid number",
    PRIORITY_INVALID: "The priority is an invalid number",
    ROUTE_CREATED_SUCCESS: "Route created successfully",
    ROUTE_UPDATED_SUCCESS: "Route updated successfully",
    EDIT_ROUTE: "Edit Route",
    ADD_ROUTE: "Add Route",
    CONFIRM_ROUTE_DELETE: "Do you wish to delete the route?",
    INTERFACE_CREATED_SUCCESS: "Interface created successfully",
    INTERFACE_UPDATED_SUCCESS: "Interface updated successfully",
    INTERFACE_DELETE_SUCCESS: "Interface deleted successfully",
    INTERFACE_TYPE: "Interface Type",
    INTERFACE_VLAN_ID: "Vlan ID",
    INTERFACE_IP: "IP",
    INTERFACE_MASK: "Mask",
    INTERFACE_MONITOR_MODE: "Monitor mode",
    VLAN_ID_INVALID: "Vlan ID must be a valid number",
    PHYSICAL_INTERFACE: "Physical Interface",
    CONFIRM_INTERFACE_DELETE: "Do you wish to delete the interface?"
  },
  CLUSTERS: {
    CLUSTERS: "Clusters",
    STATUS: "Status",
    NAME: "Name",
    IP: "IP",
    VPN: "VPN",
    VERSION: "Version",
    HARDWARE: "Hardware",
    ACTIVE_BLADES: "Active Features",
    CLUSTER_MODE: "Cluster mode",
    ERROR_NAME_ZERO: "Name cannot contain the word 'zero'",
    INTERFACES: "Cluster interfaces",
    ADD_INTERFACE: "Add cluster interface",
    EDIT_INTERFACE: "Edit cluster interface",
    INTERFACE_TYPE: "Interface type",
    INTERFACE_IP: "Cluster IP address",
    INTERFACE_VIRTUAL_IP: "Virtual IP",
    INTERFACE_MASK: "Network mask",
    INTERFACE_NAME: "Name",
    INTERFACES_SPOOFING: "Anti-spoofing",
    INTERFACE_TOPOLOGY: "Topology",
    INTERFACE_ANTI_SPOOFING: "Anti-spoofing",
    MEMBER_IP: "Member IP address",
    MEMBERS: "Members",
    PASSWORD: "One-time password",
    MEMBER: "Cluster member",
    IP_INVALID: "IP address is not valid",
    MASK_INVALID: "Network mask is not valid",
    IP_MEMBER_ERROR: "Some member(s) do not have an IP address or is not valid",
    MASK_MEMBER_ERROR: "Some member(s) do not have a network mask",
    MAXIMUM_MEMBER: "Cluster maximum number of members is 4",
    MINIMUM_MEMBER: "Cluster must have at least one member",
    MEMBER_IP_INTERFACE_ERROR:
      "Some member(s) do not have an interface IP address or is invalid",
    MEMBER_IP_INTERFACE_BLOCK_ERROR:
      "Some member ip(s) are not in the same block as the interface ip",
    INTERFACES_SYNC_VALIDATION:
      "At least one interface must be type Sync or Cluster + Sync",
    ADD_MEMBER: "Add cluster member",
    EDIT_MEMBER: "Edit cluster member",
    DELETE_MEMBER: "Delete cluster member",
    MEMBER_ADD_SUCCESS: "Cluster member added successfully",
    MEMBER_ADD_ERROR: "Error while adding cluster member",
    MEMBER_EDIT_SUCCESS: "Cluster member updated successfully",
    MEMBER_EDIT_ERROR: "Error while updating cluster member",
    MEMBER_DELETE_SUCCESS: "Cluster member deleted successfully",
    MEMBER_DELETE_ERROR: "Error while deleting cluster member",
    LOAD_ERROR: "Error while loading Clusters",
    CLUSTER_CREATE_SUCCESS: "Cluster created successfully",
    CLUSTER_CREATE_ERROR: "Error while creating Cluster",
    CLUSTER_UPDATE_SUCCESS: "Cluster updated successfully",
    CLUSTER_UPDATE_ERROR: "Error while updating Cluster",
    CLUSTER_DELETE_SUCCESS: "Cluster deleted successfully",
    CLUSTER_DELETE_ERROR: "Error while deleting Cluster",
    CLUSTER_TASK_FINISHED: "Cluster saving task is finished",
    CLUSTER_TASK_ERROR:
      "Background task for saving the Cluster could not be finished",
    CONFIRM_CLOSE_MODAL:
      "If you made changes, they will not be applied unless you submit the form. Are you sure you want to continue?",
    HELP_TITLE: "Create Cluster Help"
  },
  LICENSES: {
    LICENSES: "Licenses",
    LICENSE: "License",
    FIREWALL: "Firewall",
    EXPIRATION_DATE: "Expiration Date",
    ADD_LICENSE: "Add license",
    EDIT_LICENSE: "Edit license",
    DELETE_LICENSE: "Delete license",
    LICENSE_ADD_SUCCESS: "License added successfully",
    LICENSE_ADD_ERROR: "Error while adding license",
    LICENSE_EDIT_SUCCESS: "License updated successfully",
    LICENSE_EDIT_ERROR: "Error while updating license",
    LICENSE_DELETE_SUCCESS: "License deleted successfully",
    LICENSE_DELETE_ERROR: "Error while deleting license",
    LOAD_ERROR: "Error while loading licenses",
    HOST: "Host",
    FEATURES: "Features",
    DEVICE: "Device",
    SIGNATURE: "Signature",
    ATTACH: "Attach License",
    DETACH: "Detach License",
    ATTACHED: "Attached Licenses",
    UNATTACHED: "Unattached Licenses",
    LICENSE_ATTACH_SUCCESS: "License attached successfully",
    LICENSE_ATTACH_ERROR: "Error while attaching license",
    LICENSE_DETACH_SUCCESS: "License detached successfully",
    LICENSE_DETACH_ERROR: "Error while detaching license",
    CONFIRM_DETACH: "Do you wish to detach the license from the device?",
    CONFIRM_ATTACH: "Do you wish to attach the license to the device?",
    LOAD_DEVICE_ERROR: "Error while loading devices",
    GET_ERROR: "Error obtaining licenses from devices",
    HELP_TITTLE: "How to add a license"
  },
  SYSLOG: {
    NAME: "Name",
    SERVER: "Target server",
    PORT: "Target port",
    PROTOCOL: "Protocol",
    FORMAT: "Format",
    ENCRYPTED: "Encrypted",
    CA_CERT: "CA cert",
    CLIENT_CERT: "Client certificate",
    CLIENT_SECRET: "Client secret",
    NAME_EXISTS: "Name already exists",
    INVALID_PEM_FILE: "Pem file required",
    INVALID_PKCS_FILE: "Pkcs#12 file required",
    NEW_SYSLOG: "New syslog",
    UPDATE_SYSLOG: "Update syslog",
    CREATE_SUCCESS: "Syslog created successfully",
    UPDATE_SUCCESS: "Syslog updated successfully",
    DELETE_SUCCESS: "Syslog deleted successfully",
    CONFIRM_DELETE: "Are you sure you want to delete this syslog?",
    HELP_TITLE: "Syslog help",
    BY_CLIENT: "Syslogs by client",
    PORT_BY_CLIENT: "Port for client",
    INVALID_PORT: "Invalid port",
    UNIQUE_PORT: "Port must be unique",
  },
  REPORTS: {
    TITLE: "Reports",
    REPORT: "Report",
    FILTER: "Time Period",
    GENERATE: "Generate Report",
    DOWNLOAD: "Download",
    SEND: "Send",
    APP_FILTER: "Application and URL Filtering",
    NETWORK_ACTIVITY: "Network Activity",
    NETWORK_SECURITY: "Network Security",
    SECURITY_AD: "Security Checkup - Advanced",
    SECURITY_STATS: "Security Checkup - Statistics",
    THREAT_EMULATION: "Threat Emulation",
    THREAT_EXTRACTION: "Threat Extraction",
    THREAT_PREVENTION: "Threat Prevention",
    USER_ACTIVITY: "User Activity",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    THIS_WEEK: "This Week",
    THIS_MONTH: "This Month",
    THIS_YEAR: "This Year",
    LAST_HOUR: "Last Hour",
    LAST_24: "Last 24 Hours",
    LAST_7: "Last 7 Days",
    LAST_WEEK: "Last Week",
    LAST_14: "Last 14 Days",
    LAST_30: "Last 30 Days",
    LAST_MONTH: "Last Month",
    LAST_365: "Last 365 Days",
    LAST_YEAR: "Last Year",
    ERROR: "An error has occurred while generating the report",
    SERVICE_DOWN: "The report service is not responding",
    SCHEDULE: "Schedule a Report",
    TIME_SCHEDULE: "Schedule",
    EVERY: "Every",
    DAILY: "Day",
    WEEKLY: "Week",
    MONTHLY: "Month",
    YEARLY: "Year",
    AT: "At",
    ON: "On",
    OF: "Of",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
    JANUARY: "January",
    FEBRUARY: "February",
    MARCH: "March",
    APRIL: "April",
    MAY: "May",
    JUNE: "June",
    JULY: "July",
    AUGUST: "August",
    SEPTEMBER: "September",
    OCTOBER: "October",
    NOVEMBER: "November",
    DECEMBER: "December",
    NAME: "Name",
    MAIL: "Email",
    SUBJECT: "Subject",
    PROGRAMMED: "Programmed",
    TIMEZONE: "Timezone",
    UPDATE_ERROR: "Error updating programming information",
    NAME_EXISTS_ERROR: "Name already exists",
    CREATE_ERROR: "Error creating programmed report",
    CREATED_SUCCESS: "Programmed report created successfully",
    UPDATED_SUCCESS: "Programmed report updated successfully",
    DELETED_SUCCESS: "Programmed report deleted successfully",
    DELETE_ERROR: "Error while deleting programmed report",
    REPORTS_ERROR: "Error obtaining programmed reports",
    UPLOAD_SUCCESS: "Logo uploaded successfully",
    UPLOAD_LOGO: "Upload Logo",
    LOGO: "Report logo",
    INVALID_IMAGE_TYPE: "Uploaded file is not an image (jpg, png...)",
    INVALID_IMAGE_SIZE: "Invalid image size",
    LOGO_HELP_TITLE: "Upload logo help",
    GENERATING: "Generating...",
    SUCCESS_GENERATE: "Report generated successfully",
    CLIENT: "Client",
    ALL_CLIENTS: "All clients",
    CANCEL_REPORT_GENERATION: "The report generation will be cancelled if you exit before the report is complete"
  },
  UPDATABLE_OBJECTS: {
    NEW_UPDATABLE_OBJECT: "Add Updatable Object",
    ERROR: "Error while obtaining updatable objects from repository",
    ERROR_CREATING: "Error while creating updatable objects",
    ERROR_CHILDREN: "Error while obtaining children",
    CONFIRM_CREATE: "Warning!! All the unsaved changes will be lost. Do you wish to continue?",
    CREATED_SUCCESS: "Updatable Objects created succesfully",
    REPOSITORY_DOWNLOAD_SUCCESS: "Repository downloaded successfully",
    ERROR_DOWNLOADING: "Error downloading repository",
    DOWNLOAD_REPOSITORY: "Download objects from repository",
    DOWNLOAD_REPOSITORY_TIP: "The list is empty, fill it by using the title bar button to download the repository"
  },
  WEBHOOKS: {
    CREATE_SUCCESS: "Webhook created successfully",
    UPDATE_SUCCESS: "Webhook updated successfully",
    ERROR_CREATE: "Error while creating webhook",
    ERROR_UPDATE: "Error while updating webhook",
    NEW: "New webhook",
    UPDATE: "Update webhook",
    DELETE: "Delete webhook",
    NAME: "Name",
    TYPE: "Type",
    PATH: "Path",
    TUNNEL_DOWN: "Tunnel down warning",
    DELETE_SUCCESS: "Webhook deleted successfully",
    ERROR_DELETE: "Error while deleting webhook",
    EXPECTED_BODY: "Expected body"
  },
  MAINTENANCE: {
    MESSAGES: "System Messages",
    MESSAGES_ERROR: "Error obtaining messages",
    DELETE_ERROR: "Error while deleting message",
    DELETED_SUCCESS: "System Message deleted successfully",
    NAME: "Name",
    MESSAGE: "Message",
    UNTIL: "Until",
    CREATED_SUCCESS: "System Message created successfully",
    NAME_EXISTS_ERROR: "Name already exists",
    CREATE_ERROR: "Error while creating System Message",
    UPDATED_SUCCESS: "System Message updated successfully",
    UPDATE_ERROR: "Error while updating System Message",
    UPDATE_MESSAGE: "Update System Message",
    SYSTEM_MESSAGE: "Create System Message",
    SEND: "Send",
    CREATE: "Create"
  }
};

export default flatten(messages);
